<template>
  <v-dialog v-if="role === 'admin'" v-model="modal" persistent max-width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-menu offset-y max-height="250">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            class="body-2 text-capitalize"
            :disabled="!contactBrokers && !lead"
            :loading="!contactBrokers && !lead"
            v-bind="attrs"
            v-on="on"
          >
            <div>
              {{ getCurrentBroker(lead).name }}
            </div>
            <v-spacer></v-spacer>
            <v-icon small>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>

        <v-list elevation="0">
          <v-list-item
            v-for="(item, index) in getContactBrokers(
              contactBrokers?.ContactsList
            )"
            :key="index"
            @click="selected = item"
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <v-card>
      <v-card-title class="text-h5">
        Reasignación de lead
      </v-card-title>
      <v-card-text
        >¿Está seguro que desea
        <span v-if="getCurrentBroker(lead).name !== 'No asignado'">
          reasignar el lead

          <span class="primary--text">
            {{ validateNameLead(lead.contact_lead_name) }}
          </span>

          de
          <span class="primary--text">
            {{ getCurrentBroker(lead).name }}
          </span>
        </span>
        <span v-else>
          asignar el lead
          <span class="primary--text">
            {{ validateNameLead(lead.contact_lead_name) }}
          </span>
        </span>
        a
        <span class="primary--text">
          {{ selected.name }}
        </span>

        ?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="modal = false">
          Cancelar
        </v-btn>
        <v-btn color="primary" @click="selectedBroker(selected)">
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <div v-else>
    {{ getCurrentBroker(lead).name }}
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  props: {
    lead: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      role: localStorage.getItem("user_role"),
      innerLoading: null,
      modal: false,
      selected: false
    };
  },
  computed: {
    ...mapState({
      contactBrokers: state => state.contacts.contactList
    })
  },
  methods: {
    ...mapActions({
      fetchUpdatelLead: "leads/fetchUpdatelLead"
    }),
    ...mapMutations({
      setLoading: "leads/setLoading"
    }),
    validateNameLead(lead) {
      if (lead) {
        if (lead.trim() === "") {
          return "Sin nombre";
        } else {
          return lead;
        }
      } else {
        return "Sin nombre";
      }
    },
    getContactBrokers(items) {
      if (items) {
        return items.filter(l => {
          return l.status !== 0;
        });
      } else {
        return [];
      }
    },
    getCurrentBroker(l) {
      if (l.internal_admin && l.internal_admin.name) {
        return l.internal_admin;
      } else if (l.internal_broker && l.internal_broker.name) {
        return l.internal_broker;
      } else {
        return { name: "No asignado", role: "" };
      }
    },
    selectedBroker(item) {
      this.modal = false;
      if (item.role === "admin") {
        this.lead.internal_broker = null;
        this.lead.internal_admin = item;
        this.getContactBrokerOption(item);
      } else if (item.role === "broker") {
        this.lead.internal_admin = null;
        this.lead.internal_broker = item;
        this.getContactBrokerOption(item);
      } else {
        return null;
      }
    },
    getContactBrokerOption(option) {
      if (option) {
        this.$store.commit("leads/updateActualLead", {
          internal_broker: option
        });
        this.sendNotificationAssignBroker(option._id, option.name);
      }
    },
    sendNotificationAssignBroker(contactBrokerId, nameAssignBroker) {
      this.innerLoading = contactBrokerId;
      setTimeout(() => {
        let updateData = {
          leadId: this.lead._id,
          contactLeadId: this.lead.contact_lead_id,
          zones: this.lead.zones,
          lead: {
            contactBrokerId,
            nameAssignBroker,
            assinedPhase: this.lead.tracking_phase
          }
        };
        this.sendUpdateLead(updateData);
      }, 250);
    },
    sendUpdateLead(updateData) {
      let self = this;
      self
        .fetchUpdatelLead(updateData)
        .catch(() => {
          self.$snotify.error(
            "Error!!!",
            `Ocurrio un problema actualizar el lead.`,
            {
              closeOnClick: true,
              timeout: 4000
            }
          );
        })
        .finally(() => {
          self.setLoading(false);
          setTimeout(() => {
            self.innerLoading = false;
          }, 1000);
        });
    }
  }
};
</script>

<style scoped>
.v-dialog__container {
  display: block;
}
</style>
